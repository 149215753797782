import React from "react";
import { Container, Grid } from "@mui/material";
import MediaCard from "./service-card";

export const Services = (props) => {
  return (
    <div id="services">
      <div className="container">
        <div className="text-center section-title">
          <h2>Our Services</h2>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec.
          </p> */}
        </div>
    
        <div>
          {props.data ? (
            <Container>
              <Grid container spacing={3}>
                {props.data.map((d,i) => (
                  <Grid item key={`service-${i}`} xs={12} sm={6} md={4}>
                    <MediaCard title={d.name} image={d.image} serviceId={`service-${i}`} />
                  </Grid>
                ))}
              </Grid>
            </Container>
          ) : (
            "loading"
          )}
        </div>
      </div>
    </div>
  );
};
