import React from "react";

export const ServicesPage = (props) => {
  return (
    <div id="services-page">
      <div className="container">
        <div className="text-center section-title">
          <h2>Our Services</h2>
        </div>
        <div className="reports">
          {props.data
            ? props.data.map((d, i) => (
                <div
                  key={`service-${i}`}
                  id={`service-${i}`}
                  className={`report-card ${i % 2 === 0 ? "reverse" : ""}`}
                >
                  <div className="image-side">
                    <img src={d.image} alt={d.name} />
                  </div>
                  <div className="content-side">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
